import React from 'react';

import SEO from 'components/Seo';
import { Container } from '@axmit/clp-library';

const NotFoundPage = ({ data, location }) => {
  return (
    <Container>
      <SEO title="404: Not Found" />
      <h1 className="mt-100 mb-100">Not Found</h1>
    </Container>
  );
};

export default NotFoundPage;
